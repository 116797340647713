<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Season</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form
          @submit.prevent="saveForm"
          method="post"
          id="season-mating-form"
        >
          <v-text-field
            label="Season Start Date"
            v-model="fields.season_start_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('season_start_date')"
            :error-messages="errors['season_start_date']"
          ></v-text-field>

          <v-text-field
            label="Season Start Date (Projected)"
            v-model="fields.season_start_date_projected"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('season_start_date_projected')"
            :error-messages="errors['season_start_date_projected']"
          ></v-text-field>

          <!-- <v-switch
            label="Forecast Matches"
            v-model="fields.forecast_matches_actual"
            color="green"
            class="mt-1 mb-4"
            inset
            :error="errors.hasOwnProperty('forecast_matches_actual')"
            :error-messages="errors['forecast_matches_actual']"
          ></v-switch> -->
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="season-mating-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        season_start_date: null,
        season_start_date_projected: null,
        forecast_matches_actual: false,
      },

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {},

  methods: {
    openForm: function (season = null) {
      if (season !== null) {
        this.season = season;

        this.fields.season_start_date = season.season_start_date;
        this.fields.season_start_date_projected =
          season.season_start_date_projected;
        this.fields.forecast_matches_actual = season.forecast_matches_actual;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        litterId: this.$route.params.litterId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.fields.formName = "season";

      this.$store
        .dispatch("lhl/litters/saveLitter", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.season_start_date = null;
      this.fields.season_start_date_projected = null;
      this.fields.forecast_matches_actual = false;
    },
  },
};
</script>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Mating Records</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.recordForm.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Record</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="litter.matings"
              no-data-text="No records"
              :items-per-page="-1"
              :item-class="row_classes"
            >
              <template v-slot:item.day_number="{ item }">
                #{{ item.day_number }}
              </template>

              <template v-slot:item.date="{ item }">
                {{ formatDate(item.date) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="blue lighten-4 blue--text"
                  @click="$refs.recordForm.openForm(item)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item)"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1"></div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.seasonForm.openForm(litter)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Season Start Date</th>
                  <td>
                    <div if="litter.season_start_date">
                      {{ formatDate(litter.season_start_date) }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Season Start Date (Projected)</th>
                  <td>
                    <div if="litter.season_start_date_projected">
                      {{ formatDate(litter.season_start_date_projected) }}
                    </div>
                  </td>
                </tr>

                <!-- <tr>
                  <th>Forecast Matches Actual</th>
                  <td>
                    {{ litter.forecast_matches_actual ? "Yes" : "No" }}
                  </td>
                </tr> -->
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <RecordForm ref="recordForm" />
      <SeasonForm ref="seasonForm" />

      <v-dialog
        scrollable
        v-model="deleteRecord.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Delete Record</v-card-title>
          <v-card-text
            >Are you sure you want to delete this record?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteRecord.loading"
              @click="saveDelete()"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import RecordForm from "../components/mating/RecordForm";
import SeasonForm from "../components/mating/SeasonForm";

export default {
  components: { RecordForm, SeasonForm },

  data() {
    return {
      searchTerm: "",

      tableHeaders: [
        { text: "Date", value: "date" },
        { text: "Day Number", value: "day_number" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      deleteRecord: {
        dialog: false,
        model: {},
        loading: false,
      },
    };
  },

  computed: {
    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
  },

  methods: {
    row_classes(item) {
      if (item.last_mating == true) {
        return "lighten-4 green";
      }
    },

    openDelete(model) {
      this.deleteRecord.model = model;
      this.deleteRecord.dialog = true;
    },

    resetDelete() {
      this.deleteRecord.dialog = false;
      this.deleteRecord.model = {};
      this.deleteRecord.loading = false;
    },

    saveDelete() {
      this.deleteRecord.loading = true;

      let playloadUrl = null;
      let args = [];

      playloadUrl = "lhl/litters/deleteRecord";
      args = {
        appId: this.$route.params.id,
        litterId: this.$route.params.litterId,
        recordId: this.deleteRecord.model.id,
      };

      this.$store
        .dispatch(playloadUrl, args)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteRecord.loading = false;
        });
    },
  },
};
</script>
